// app/firebase/config.client.ts
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBTWj5jICcEVG8EMTrnmy_7hfQU8cOtGGE',
  authDomain: 'flow-ai-prod.firebaseapp.com',
  projectId: 'flow-ai-prod',
  storageBucket: 'flow-ai-prod.appspot.com',
  messagingSenderId: '471056905783',
  appId: '1:471056905783:web:b4471171cb73e56fde31b2',
  measurementId: 'G-G5ELJVEF47',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Firebase: any;

if (!Firebase?.apps?.length) {
  Firebase = initializeApp(firebaseConfig);
}

export const authClient = getAuth(Firebase);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  const result = await signInWithPopup(authClient, provider);
  return result.user;
};

export const signInWithCredentials = async (
  email: string,
  password: string
) => {
  const result = await signInWithEmailAndPassword(authClient, email, password);
  return result.user;
};

export const signUp = async (email: string, password: string) => {
  const result = await createUserWithEmailAndPassword(
    authClient,
    email,
    password
  );
  return result.user;
};

export const logout = async () => signOut(authClient);
